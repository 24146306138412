import { useEffect, useState } from 'react';
import axios from "../Axios/Axios";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { BsFillChatLeftDotsFill } from "react-icons/bs";
import { IoMdDownload } from "react-icons/io";
import { useParams } from 'react-router-dom';
import logoIMG from '../Images/logo.png';


import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  const [userData, setUserData] = useState({});
  const { pageId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logoURL, setLogoURL]=useState("");
  // const [profileURL, setprofileURL]=useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    address: "",
    message: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`users/formdata/${pageId}`);

        console.log("Response: ", res.data); 

        setUserData(res.data.response);
        res.data.response.profilePhotoObj.logoURL? setLogoURL(res.data.response.profilePhotoObj.logoURL): setLogoURL("");
        // setprofileURL(res.data.response.profilePhotoObj.contentURL);
      } catch (e) {
        console.error("Error while fetching data: ", e.message);
      }
    };

    fetchData();
  }, [pageId]);

  const generateVCF = () => {
    const vcfContent = `BEGIN:VCARD
VERSION:3.0
N:${userData.name}
TITLE:${userData.designation}
ORG:${userData.companyName}
EMAIL:${userData.email}
TEL:${userData.mobile}
ADR:${userData.city}
URL;TYPE=linkedin:${userData.linkedinUrl} 
URL;TYPE=twitter:${userData.twitterUrl} 
URL;TYPE=instagram:${userData.instagramUrl} 
URL;TYPE=Website:${userData.webSiteUrl}
PHOTO;TYPE=JPEG;VALUE=URL:${userData.profilePhotoObj?.contentURL}
PHOTO;TYPE=JPEG;VALUE=URL:${logoURL}
END:VCARD`;

    const blob = new Blob([vcfContent], { type: "text/vcard;charset=utf-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "contact.vcf");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLeadform = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const res = await axios.post(`lead/${pageId}`, formData, {});
      console.log(res);
      if (res.status === 200) {
        toast.success("Submitted", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setIsModalOpen(false);
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error("User already exists. Please choose a different email.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      if (err.response && err.response.status === 500) {
        toast.error("Unexpected Error Occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error("Please try again after some time.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
    setIsSubmitting(false);
  };

  const isFormValid = () => {
    const { name, email } = formData;
    return name.trim() !== "" && email.trim() !== "";
  };

  console.log("userData---------------------->", userData);

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="h-[100vh] flex justify-center items-center w-full md:w-[100%]">
        <div className="relative border flex flex-col h-[100vh] w-[100vw] overflow-hidden">
        {userData && <img className='relative z-0 w-auto h-full md:object-none object-cover' src={userData.profilePhotoObj?.contentURL} alt=""/>}
          {/* {userData.imageObj && userData.imageObj.map((image, index) => (
            <img
              key={index}
              src={image?.contentURL}
              alt={`Image ${index}`}
              className="relative z-0 w-auto h-full md:object-none object-cover"
            />

          ))} */}
          <img src={logoURL} alt="" className=' absolute top-[2%] left-[80%] md:left-[60%] w-[15%] object-contain h-[10%] opacity-90 ' />
          {/* <img src="https://molog.in/molog-logo.png" alt="" className=' absolute top-[2%] left-[80%] md:left-[60%] w-[15%] object-contain h-[10%] opacity-90 ' /> */}
          
          <section className="h-[365px] w-full bottom-0 absolute z-2 bg-gradient-to-t from-black via-black to-transparent  md:w-[50%] md:ml-[25%]">
            <div className="flex flex-col gap-2 mx-7 py-8 text-white leading-11 md:leading-14 lg:leading-16 xl:leading-20 text-left">
              <h1 className='font-bold text-3xl font-helvetica'>
                {userData?.name}
              </h1>
              <h2 className='font-light text-base font-helvetica'>{userData.designation}</h2>
            </div>
            <div className='flex gap-3 mx-6'>
              {userData.mobile && (
                <button className='bg-[#D9D9D91A] hover:bg-[#D9D9D91F] w-28 h-12 rounded-sm flex justify-center items-center'>
                  <a href={`tel:${userData.mobile}`}>
                    <IoCall color='white' fontSize={'large'} />
                  </a>
                </button>
              )}
              {userData.email && (
                <button className='bg-[#D9D9D91A] hover:bg-[#D9D9D91F] w-28 h-12 rounded-sm flex justify-center items-center'>
                  <a href={`mailto:${userData.email}`}>
                    <IoMdMail color='white' fontSize={'large'} />
                  </a>
                </button>
              )}
              {userData.sms && (
                <button className='bg-[#D9D9D91A] hover:bg-[#D9D9D91F] w-28 h-12 rounded-sm flex justify-center items-center'>
                  <a href={`sms:${userData.sms}`}>
                    <BsFillChatLeftDotsFill color='white' />
                  </a>
                </button>
              )}
            </div>
            <div className='my-5 mx-6 flex flex-col gap-2 justify-center items-center '>
              {/* <button onClick={toggleModal} className='w-full h-8 bg-[#D9D9D91A] hover:bg-[#D9D9D91F] text-white rounded-full font-semibold text-sm tracking-wide text-center font-helvetica'>
                Share Your Details
              </button> */}
              <button className='w-full md:w-[90%] h-8 bg-[#1BB14C] hover:bg-[#D9D9D91F] text-white rounded-full font-semibold text-sm tracking-wide gap-2 font-helvetica flex justify-center items-center' onClick={generateVCF}>
                <IoMdDownload color='white' />Save
              </button>
            </div>
            <div className=' flex justify-center items-center'>
              <img src={logoIMG} height={23} width={73} alt="Tap and Tag" />
            </div>
          </section>
        </div>
      </div>


      {/* {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 ">
          <div className="bg-white p-8 rounded-md shadow-lg w-[320px] h-[550px] -mt-8">
            <div className="w-full flex mb-4 justify-between items-center">
              <h2 className="text-xl font-bold mx-auto text-[#1BB14C]">Share Your Details</h2>
              <span onClick={toggleModal} className="text-3xl text-black cursor-pointer p-2">&times;</span>
            </div>
            <form onSubmit={handleLeadform}>
              <input onChange={handleChange} type="text" name="name" placeholder="Name" className="block w-full border border-gray-300 rounded mb-2 px-4 py-2 focus:border-[#1BB14C] focus:outline-none" />
              <input onChange={handleChange} type="email" name="email" placeholder="Email" className="block w-full border border-gray-300 rounded mb-4 px-4 py-2 focus:border-[#1BB14C] focus:outline-none" />
              <input onChange={handleChange} type="text" name="phone" placeholder="Phone" className="block w-full border border-gray-300 rounded mb-4 px-4 py-2 focus:border-[#1BB14C] focus:outline-none" />
              <input onChange={handleChange} type="text" name="companyName" placeholder="Company Name" className="block w-full border border-gray-300 rounded mb-4 px-4 py-2 focus:border-[#1BB14C] focus:outline-none" />
              <input onChange={handleChange} type="text" name="address" placeholder="Address" className="block w-full border border-gray-300 rounded mb-4 px-4 py-2 focus:border-[#1BB14C] focus:outline-none" />
              <textarea onChange={handleChange} name="message" placeholder="Message" className="block w-full border border-gray-300 rounded mb-4 px-4 py-2 focus:border-[#1BB14C] focus:outline-none"></textarea>
              <button type="submit" className={`block w-full py-2 text-white rounded ${isFormValid() ? 'bg-[#1BB14C]' : 'bg-gray-400 cursor-not-allowed'}`} disabled={!isFormValid() || isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      )} */}
    </>
  );
}

export default App;
